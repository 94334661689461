.title {
  font-family: $title;
}

.text {
  font-family: $text;
}

.caps {
  text-transform: uppercase;
}
.small-cap {
  font-variant: small-caps;
}

strong, .bold {
  font-weight: 800;
}

.small {
  font-size: 80%;
}

.text-orange {
  color: $orange;
}

h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: $orange;
  @media (max-width: 425px) {
    font-size: 3rem;
  }
}

h2 {
  font-size: 30px;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

h3 {
  font-size: 23px;
}

p, li {
  line-height: 27.2px;
  margin-bottom: 30px;
  &.sub-title {
    font-size: 24px;
    line-height: 31.2px;
    font-weight: 700;
    color: $dark-blue;
  }
}