header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 5px #0000008d;
  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    z-index: 6;
    &.active {
      .mobile-nav-trigger span {
        background: #232323;
        &:first-of-type{
          transform: rotate(45deg) translate(1px, -1px);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:last-of-type {
          transform: rotate(-45deg) translate(-2px, -1px);
        }
      }

      .nav-links {
        transform: translateX(0);
        transition: all 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
      }

      .nav-mobile-dark {
        pointer-events: all;
        opacity: 0.7;
        transition: all 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
      }
    }
  }

  .nav-logo-container {
    display: flex;
    align-items: center;
    img {
      height: 50px;
    }
  }

  .mobile-nav-trigger {
    display: none;
    position: absolute;
    top: 35px;
    right: 30px;
    cursor: pointer;
    outline: none;
    span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 6px;
      position: relative;
      background: #494949;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  opacity 0.2s ease;

      &:first-of-type {
        transform-origin: 0% 0%;
      }
      &:nth-of-type(2) {
        transform-origin: 0% 100%;
      }
    }
  }

  .nav-links ul {
    display: flex;
    align-items: center;
    li {
      margin-bottom: 0;
    }
    a {
      color: $black;
      font-family: $title;
      font-size: 20px;
      font-weight: 800;
      text-decoration: none;
      text-transform: capitalize;
      border-bottom: 3px solid $white;
      margin: 0 10px;
      &:hover {
        color: $orange;
        border-bottom: 3px solid $orange;
      }
      &.link-active {
        color: $dark-blue;
        border-bottom: 3px solid $dark-blue;
      }
    }
  }

  @media (max-width: 768px) {
    .nav-mobile-dark {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #000000;
      opacity: 0;
      pointer-events: none;
    }
    .mobile-nav-trigger {
      display: block;
    }

    .nav-links {
      position: absolute;
      top: 0;
      right: 0;
      width: 280px;
      height: 100vh;
      transform: translateX(290px);
      background-color: $orange;
      ul {
        display: block;
        padding-top: 50px;
        li {
          padding: 20px;
          border-bottom: 1px solid $white;

        }
        a {
          font-size: 24px;
          margin: 0;
          border: none;
          &:hover, &.active {
            border: none;
          }
        }
      }
    }
  }
}