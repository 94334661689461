#stay-connected {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .stay-connected-txt {
    padding-right: 50px;
  }

  .social-media-container {
    display: flex;
    a {
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      width: 60px;
      height: 60px;
      &:hover {
        background-color: $dark-blue;
        img {
          filter: invert(1);
        }
      }
    }
    img {
      width: 50px;
    }
  }

  .stay-connected-img {
    .circle-container {
      width: 250px;
      max-width: 100%;
      padding: 40px;
      border-radius: 50%;
      background-color: #fff;
      border: 5px solid $dark-blue;
      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    .container {
      flex-direction: column-reverse
    }

    h2 {
      text-align: center;
      margin-top: 50px;
    }
    .stay-connected-txt {
      padding-right: 0;
    }
    .social-media-container {
      justify-content: center;
    }
  }
}