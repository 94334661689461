#home {
    .banner {
        gap: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .banner-text,
        .banner-img {
            width: 50%;
            .sub-title {
                max-width: 430px;
            }
        }
        @media (min-width: 769px) {
            .banner-img {
                display: flex;
                justify-content: end;
                img {
                    margin: 0;
                }
            }
        }
        @media (max-width: 768px) {
            flex-direction: column;
            .banner-text,
            .banner-img {
                width: 100%;
            }
            .btn {
                margin: 0 auto;
            }
        }
    }

    #discover {
        .btn-container {
            margin-bottom: 30px;
        }
        .discover-txt-container {
            margin-bottom: 50px;
            p {
                margin-bottom: 15px;
            }
        }
    }

    #join-thee-fair {
        .join-txt-container {
            display: flex;
            gap: 80px;
            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50%;
            }
            @media (max-width: 768px) {
                flex-direction: column;
                & > div {
                    padding: 0;
                    width: 100%;
                    align-items: flex-start;
                }
            }
        }
        h2 {
            color: $light-orange;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    #celebrate-culture {
        .list-container {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            gap: 50px;
        }
        .img-container {
            width: 33%;
        }
        .no-bullet-border {
            width: 67%;
        }
        @media (max-width: 768px) {
            .list-container {
                flex-direction: column-reverse;
                padding: 0 30px;
                ul {
                    padding-left: 0;
                    margin-top: 20px;
                }
            }
            .img-container,
            .no-bullet-border {
                width: 100%;
            }
        }
        @media (max-width: 375px) {
            .list-container {
                padding: 0 20px;
            }
        }

    }
}