.lightbox {
    .lb-data {
        .lb-number {
            display: none !important;
        }
        .lb-close {
            opacity: 1;
        }
    }
}
.modal {
    display: none;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background-color: #000000b3;
    width: 100vw;
    height: 100vh;
    z-index: 1001;

    .modal-content {
        position: relative;
        padding: 4px;
        border-radius: 4px;
        background-color: #fff;
        width: 80%;

    }

    .close {
        position: absolute;
        bottom: 0px;
        right: 10px;
        transform: translateY(40px);
        display: block;
        width: 30px;
        height: 30px;
        background: url('../img/global/icon-close.webp') no-repeat;
        filter: invert(1);
        background-size: contain;
    }

    &.video-modal {
        .modal-content {
            display: flex;
            flex-direction: column;
            background-color: transparent;

            h3 {
                margin-bottom: 10px;
                color: #fff;
                text-align: center;
            }

            video {
                height: 80vh;
                max-width: 95vw;
                background-color: #09181e;
            }
        }
    }

    &#modal-location {

    }

    &.active {
        display: flex;
    }
}