.gallery-container {
    width: 100% !important;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    grid-auto-rows: 120px;
    grid-auto-flow: dense;
    max-width: 1000px;
    margin: 0 auto;

    & > a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2em;
    }

    & > a > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .horizontal {
        grid-column: span 2;
    }

    .vertical {
        grid-row: span 2;
    }

    .big {
        grid-column: span 2;
        grid-row: span 2;
    }
}