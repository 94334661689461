@import url('https://fonts.googleapis.com/css2?family=Averia+Libre&family=Raleway:wght@400;700&display=swap');

@import "00_abstracts/_variables.scss";
@import "01_vendors/_normalize.scss";
@import "02_components/_accordion.scss";
@import "02_components/_buttons.scss";
@import "02_components/_img.scss";
@import "02_components/_lists.scss";
@import "02_components/_modal.scss";
@import "02_components/_spacing.scss";
@import "02_components/_typography.scss";
@import "03_layout/_carousel.scss";
@import "03_layout/_flex.scss";
@import "03_layout/_footer.scss";
@import "03_layout/_gallery.scss";
@import "03_layout/_header.scss";
@import "03_layout/_stay-connected.scss";
@import "04_section/_about.scss";
@import "04_section/_events.scss";
@import "04_section/_home.scss";
@import "04_section/_vendor.scss";

html, body {
  color: $textColor;
  font-family: $text;
  font-size: 16px;
  line-height: 1.25;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.nowrap { white-space: nowrap; }

main {
  margin-top: 90px;
}

.container {
  margin: 0 auto;
  max-width: 1300px;
  padding: 0 20px;
  position: relative;
}

section, .banner {
  padding-top: 75px;
  padding-bottom: 75px;
}

section, footer, header {
  &.dark {
    background-color: $dark-blue;
    color: $white;
  }
  &.orange {
    background-color: $light-orange;
  }
}