footer {
  padding: 40px 10px;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 2em;
  }
  p {
    margin-bottom: 0;
  }
}