#vendor {
    section, .banner {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    p {
        margin-bottom: 15px;
    }

    ul, ol {
        padding-left: 2.5em;
    }

    .vendors-page {
        .btn {
            margin-top: 50px;
        }
    }

    .img-container {
        width: 100%;
        margin: 50px auto 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        div {
            width: 80px;
        }
        img {
            width: 100%;
        }
        @media (max-width: 1204px) {
            max-width: 519px;
        }
        @media (max-width: 419px) {
            max-width: 519px;
            div {
                &:nth-of-type(7),
                &:nth-of-type(8) {
                    display: none;
                }
            }
        }
    }

    // #recap {
    //     padding-top: 50px;
    //     padding-bottom: 80px;
    //     h2 {
    //         text-align: center;
    //         font-size: 4.2em;
    //     }
    //     .recap-container {
    //         display: flex;
    //         justify-content: space-between;
    //         gap: 20px;
    //         text-align: center;
    //     }

    //     p {
    //         font-size: 1.5em;
    //         margin-bottom: 0;
    //     }

    //     h3 {
    //         font-size: 4em;
    //         color: $orange;
    //     }
    //     @media (max-width: 835px) {
    //         h2 {
    //             font-size: 2.5em;
    //         }
    //         h3 {
    //             font-size: 2.5em;
    //         }
    //         .recap-container {
    //             flex-direction: column;
    //             gap: 50px;
    //         }
    //     }
    // }

    #faq {
        h2 {
            text-align: center;
        }
    }

    #how-to-apply {
        .btn {
            margin-top: 75px;
        }
    }

    #apply {
        .btn {
            margin: 50px auto 20px;
        }
    }
}