.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-vertically-aligned {
  display: flex;
  align-items: center;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: auto;
  }
}

.flex-center {
  display: flex;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

.flex-1-2 {
  width: 50%;
}

.flex-1-3 {
  width: 33%;
}

.flex-2-3 {
  width: 66%;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.width-100 {
  width: 100%;
}