#about {
    .video-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        grid-gap: 10px;

        img {
            aspect-ratio: 3/5;
            width: 100%;
            max-width: 155px;
            object-fit: cover;
            cursor: pointer;
            border: 3px solid $white;
            opacity: 0.7;
            transition: all 0.3s ease-in-out;
            &:hover {
                opacity: 1;
                border: 3px solid $dark-blue;
            }
        }

        @media (max-width: 1024px) {
            justify-content: center;
        }

        @media (max-width: 425px) {
            justify-content: space-between;
            img {
                width: 125px;
            }
        }
    }

    .mission-container, .why-we-do-container {
        .text-container {
            width: 60%;
        }
        .image-container {
            width: 40%;
        }
    }

    .what-we-do-container {
        flex-direction: row-reverse;
        p {
            margin-bottom: 10px;
        }
        .text-container {
            width: 67%;
        }
        .image-container {
            width: 33%;
        }
    }

    .mission-container, .what-we-do-container, .why-we-do-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
        @media (max-width: 728px) {
            flex-direction: column;
            gap: 20px;
            .text-container, .image-container {
                width: 100%;
                img {
                    max-width: 400px;

                }
            }
            ul {
                margin-bottom: 30px;
            }
        }
    }

}