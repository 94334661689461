.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.mb-30 {
    margin-bottom: 30px;
}

.pr-20 {
    padding-right: 20px;
}

.pl-20 {
    padding-left: 20px;
}