.btn {
    padding: 15px 40px;
    background-color: $dark-blue;
    border-radius: 50px;
    color: $white;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: .5px;
    display: block;
    width: max-content;
    // transform: translate(0px, 0px);
    transition: all .1s linear;

    &:hover {
        background-position: 0% 0%;
        box-shadow: 2px 2px 2px #aaaaaa;
        // transform: translate(-2px, -2px);
        color: $black;
        background-color: $light-orange;
    }
    &:active {
        box-shadow: 0px 0px 0px #0000008d;
        transform: translate(0px, 0px);
    }

    &.btn-special {
        padding: 20px 40px;
        background-color: $light-orange;
        color: $black;
        margin: 0 auto;
        font-size: 26px;
        &:hover {
            background-color: $black;
            color: $orange;
        }
    }
}

.link {
    color: $orange;
    font-weight: bold;
    letter-spacing: .5px;
    text-decoration: none;
    border-bottom: 2px solid $orange;
    &:hover {
        color: $dark-blue;
        border-bottom: 2px solid $dark-blue;
    }
}


.dark {
    .btn {
        background-color: $white;
        color: $dark-blue;

        &:hover {
            color: $black;
            background-color: $light-orange;
        }
    }
}