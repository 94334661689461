// COLOR
$black: #292b2c;
$white: #fff;
$orange: #ff6d6d;
$light-orange: #ffb1b1;
$teal: #0d9dad;
$dark-blue: #1d414d;
$grey: #6d6d6d;

$primaryColor: $white;
$secondaryColor: $black;
$thirdColor: $dark-blue;

$textColor: $black;


// FONT
$title: 'Averia Libre', cursive;
$text: 'Raleway', sans-serif;

// OTHERs
$trans: all 200ms ease-in-out;
$shadow: 5px 5px 10px #ffffff89;

$border: 3px solid $white;

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;