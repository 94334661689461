.accordion {
    margin-bottom: 30px;
    .accordion-item {
        // border-radius: 20px;
        // border: 3px solid $dark-blue;
        border-bottom: 3px solid $dark-blue;
        overflow: hidden;
        .accordion-title {
            cursor: pointer;
            padding: 1rem 2rem ;
            display: flex;
            // background-color: s$dark-blue;
            // color: $white;
            justify-content: space-between;
            align-items: center;
        }

        .icon {
            width: 24px;
            height: 24px;
            position: relative;

            span {
                background-color: black;
                &:first-of-type {
                    display: block;
                    position: absolute;
                    top: calc(50% - 2px);
                    width: 100%;
                    height: 4px;
                    // background-color: $white;

                    transform: rotate(0deg);
                    transform-origin: center;
                    transition: all linear 0.4s;
                }
                &:last-of-type {
                    display: block;
                    position: absolute;
                    top: calc(50% - 2px);
                    width: 100%;
                    height: 4px;
                    // background-color: $white;

                    transform: rotate(90deg);
                    transform-origin: center;
                    transition: all linear 0.2s;
                }
            }
        }

        .accordion-content {
            max-height: 0px;
            overflow: hidden;
            transition: all linear 0.2s;
            .accordion-txt {
                padding: 1rem 2rem 2rem;
            }
        }

        &.active {
            .accordion-content {
                max-height: 1000px;
            }

            .icon {
                span {
                    &:first-of-type {
                        transform: rotate(180deg);
                    }
                    &:last-of-type {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}